import AjaxService from '@services/AjaxService';
import Vue from 'vue';
import LoginStatusStore from '@services/LoginStatusStore';
import SelectOption from '@form/select/SelectOption';
import {convertToOptionsFromCollection} from '@js/functions/form/select';

export default class FirmRepository {
  private readonly ajaxService: AjaxService;

  private readonly loginStatusStore: LoginStatusStore;

  private readonly store = new Vue({
    data: {
      activeBranchId: null,
      firmBranchOptions: [],
      firmBranchData: {},
    },
  });

  public constructor(ajaxService: AjaxService, loginStatusStore: LoginStatusStore) {
    this.ajaxService = ajaxService;
    this.loginStatusStore = loginStatusStore;
  }

  public appraiserUpdate(firmId: number, data: any): Promise<any> {
    const url = '/api/firms/' + firmId + '/appraisers';
    return this.ajaxService.putJson(url, {data: data});
  }

  public buildDocumentDownloadUrl(documentId: number): string {
    return '/api/documents/' + documentId + '/download';
  }

  public buildFileDownloadUrl(firmId: number, fileId: number): string {
    return '/api/firms/' + firmId + '/files/' + fileId;
  }

  public chooseActiveBranch(branchId: number): void {
    this.store.$data.activeBranchId = branchId;
  }

  public deleteDocument(documentId: number): Promise<any> {
    const url = '/api/documents/' + documentId + '/remove';
    return this.ajaxService.sendDelete(url);
  }

  public deleteFile(firmId: number, fileId: number): Promise<any> {
    const url = '/api/firms/' + firmId + '/files/' + fileId;
    return this.ajaxService.sendDelete(url);
  }

  public getActiveBranch(): any {
    if (!this.store.$data.activeBranchId) {
      return this.loginStatusStore.getCurrentFirmAttributes();
    }
    if (this.store.$data.activeBranchId === -1) {
      return {firmId: -1, name: 'Alle Filialen'}
    }
    return this.store.$data.firmBranchData[this.store.$data.activeBranchId];
  }

  /**
   * Type must be one of [firm|firmGroup|firmAssociation|reseller]
   */
  public getAvailableDocumentTypes(
    id: number,
    type: string
  ): Promise<any> {
    const url = '/api/documentTypes/' + type + '/' + id;

    return this.ajaxService.getJson(url)
  }

  public getAvailableFiles(firmId: number): Promise<any> {
    const url = '/api/firms/' + firmId + '/files';
    return this.ajaxService.getJson(url)
  }

  public getDropdownOptionsForRecordCreation(): Promise<SelectOption[]> {
    return this.indexForRecordCreate().then((response) => {
      return this.buildDropdownOptions(response);
    });
  }

  public getDropdownOptionsForRecordFilter(): Promise<SelectOption[]> {
    return this.indexForRecordFilter().then((response) => {
      return this.buildDropdownOptions(response);
    });
  }

  public getDropdownOptionsForRecordMoval(excludedFirmIds: number[]): Promise<SelectOption[]> {
    return this.indexForRecordMoval(excludedFirmIds).then((response) => {
      return this.buildDropdownOptions(response);
    });
  }

  public getFirmAssociations(): Promise<any> {
    const url = '/api/firm-associations';
    return this.ajaxService.getJson(url)
  }

  public getFirmBranchOptions(): any[] {
    return this.store.$data.firmBranchOptions;
  }

  public getFirmBranchOptionsWithAll(): any[] {
    let options = [{value: -1, text: 'Alle Filialen'}];
    options = options.concat(this.store.$data.firmBranchOptions);
    return options;
  }

  /**
   * Type must be one of [firm|firmGroup|firmAssociation|reseller]
   */
  public handleDocumentExcludeToggle(
    documentTypeId: number,
    contextId: number,
    contextType: string,
    shouldExclude: boolean
  ): Promise<any> {
    const url = '/api/documents/' + documentTypeId + '/' + contextType + '/' + contextId + '/exclude';
    return this.ajaxService.postJson(url, {data: {'shouldExclude': shouldExclude}});
  }

  public hasBranches(): boolean {
    return this.store.$data.firmBranchOptions.length > 1;
  }

  public loadBranches(): Promise<any> {
    const promise = this.showCurrentBranches();
    promise.then(
      (response) => {
        this.store.$data.firmBranchOptions = [];
        response.data.forEach((branch: any) => {
          const firmId = branch.attributes.firmId;
          this.store.$data.firmBranchOptions.push({value: firmId, text: branch.attributes.nameWithAffix});
          this.store.$data.firmBranchData[firmId] = branch.attributes;
        });
      },
      () => {
        this.reset();
      }
    );
    return promise;
  }

  public reset(): void {
    this.store.$data.activeBranchId = null;
    this.store.$data.firmBranchOptions = [];
    this.store.$data.firmBranchData = {};
  }

  public showCurrent(): Promise<any> {
    return this.ajaxService.getJson('/api/firms/current');
  }

  public showCurrentBranches(): Promise<any> {
    return this.ajaxService.getJson('/api/firms/current/branches');
  }

  public showForRecordCreate(firmId: number): Promise<any> {
    return this.ajaxService.getJson('/api/firms/' + firmId + '/record-create');
  }

  private buildDropdownOptions(response: any): SelectOption[] {
    return convertToOptionsFromCollection(response.data);
  }

  private indexForRecordCreate(): Promise<any> {
    return this.ajaxService.getJson('/api/firms/record-create');
  }

  private indexForRecordFilter(): Promise<any> {
    return this.ajaxService.getJson('/api/firms/record-filter');
  }

  private indexForRecordMoval(excludedFirmIds: number[]) {
    let url = '/api/firms/record-moval';
    if (excludedFirmIds.length) {
      url += '?excludedFirmIds=' + excludedFirmIds.join(',');
    }
    return this.ajaxService.getJson(url);
  }
}
