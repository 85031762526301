import VueI18n from 'vue-i18n';
import VueRouter, {RouteConfig} from 'vue-router';
import Vue from 'vue';

import {PluginFunction, PluginObject} from 'vue/types/plugin';

export default class VueFactory {
  private vueConstructorOptions: any = {
    data: {},
  };

  public createVue(rootComponent: any): Vue {
    return new Vue({
      ...this.vueConstructorOptions,
      methods: this.getGlobalMethods(),
      render: h => h(rootComponent),
    });
  }

  public createVueForDesktopApp(selector: string): Vue {
    return new Vue({'el': selector, ...this.vueConstructorOptions});
  }

  public getGlobalMethods(): any {
    return {
      setInputValue(value: any, setterCallback: any) {
        // We need to set the value to an empty string first to make sure the value is updated
        // event if the interal value has not changed. Example: input of 1.500 will not be
        // changed to 1.50 without this!
        setterCallback('');
        this.$nextTick(() => {
          setterCallback(value);
        });
      },
    };
  }

  public initializeI18n(): VueI18n {
    Vue.use(VueI18n);

    const i18n = new VueI18n({
      locale: 'de_DE',
      messages: {},
    });

    this.vueConstructorOptions.i18n = i18n;
    return i18n;
  }

  public setData(data: any): void {
    this.vueConstructorOptions.data = data;
  }

  public usePlugin(plugin: PluginObject<any> | PluginFunction<any>, ...options: any[]): void {
    Vue.use(plugin, ...options);
  }

  public useRouter(routes: RouteConfig[], routerOptions: any = {}): void {
    if (routes.length === 0) {
      return;
    }
    Vue.use(VueRouter);
    this.vueConstructorOptions.router = new VueRouter({
      ...routerOptions,
      routes,
    });
  }
}
