import AjaxService from '@services/AjaxService';
import FirmRepository from '@repositories/FirmRepository';
import ClaimTypeEnum from '@js/enums/damage/ClaimTypeEnum';
import SelectOption from '@form/select/SelectOption';
import {convertToOptionsFromCollection} from '@js/functions/form/select';
import Form from '@form/Form';
import UrlParameterInterface from '@repositories/UrlParameterInterface';

interface FilterInterface {
  property: string,
  value: string,
}

export default class RecordRepository {
  private readonly ajaxService: AjaxService;

  private readonly firmRepository: FirmRepository;

  public constructor(ajaxService: AjaxService, firmRepository: FirmRepository) {
    this.ajaxService = ajaxService;
    this.firmRepository = firmRepository;
  }

  public accidentDataShow(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/accident';
    return this.ajaxService.getJson(url);
  }

  public accidentDataUpdate(recordId: string, attributes: any): Promise<any> {
    const url = '/api/records/' + recordId + '/accident';
    return this.ajaxService.putJson(url, {data: {attributes: attributes}});
  }

  public appraiserAssign(recordId: string, attributes: any): Promise<any> {
    const url = '/api/records/' + recordId + '/appraiser';
    return this.ajaxService.putJson(url, {data: {attributes}});
  }

  public appraiserSendOrderMail(recordId: string, recipientUserId?: number): Promise<any> {
    const url = '/de/Schaden-Manager/Akte/ajax/type/sendAppraiserOrderMail/vehicleDamageRecordId/' + recordId;
    let postData = {};
    if (recipientUserId) {
      postData = {data: {attributes: {recipientUserId}}};
    }
    return this.ajaxService.postJson(url, postData);
  }

  public appraiserShow(recordId: string, appraiserFirmId?: number): Promise<any> {
    let url = '/api/records/' + recordId + '/appraiser';
    if (appraiserFirmId) {
      url += '?appraiserFirmId=' + appraiserFirmId;
    }
    return this.ajaxService.getJson(url);
  }

  public appraiserUserIndex(recordId: string, appraiserFirmId: number): Promise<any> {
    const url = `/api/records/${recordId}/appraiser/${appraiserFirmId}/users`;
    return this.ajaxService.getJson(url);
  }

  public buildFileDownloadUrl(recordId: number, fileId: number): string {
    return `/api/records/${recordId}/files/${fileId}`;
  }

  public buildThumbnailDownloadUrl(recordId: number, fileId: number, thumbnailType: string): string {
    return `/api/records/${recordId}/thumbnails/${thumbnailType}/${fileId}`;
  }

  public cancelReceiptEntry(recordId: number, entryId: string, form: Form): Promise<any> {
    const url = '/api/records/' + recordId + '/receipt-entries/' + entryId + '/cancel';
    return this.ajaxService.postFormData(url, form);
  }

  public createReceiptEntry(recordId: number, form: Form): Promise<any> {
    const url = '/api/records/' + recordId + '/receipt-entries';
    return this.ajaxService.postFormData(url, form);
  }

  public createRecord(data: any): Promise<any> {
    const url = '/api/records';
    data = this.prepareRecordCreation(data);
    return this.ajaxService.postJson(url, data);
  }

  public createRecordValidation(data: any, filterMessages: string[]): Promise<any> {
    const filterParams: string[] = [];
    filterMessages.forEach((filter) => {
      filterParams.push('filter[]=' + filter);
    });
    const url = '/api/records/validate?' + filterParams.join('&');
    data = this.prepareRecordCreation(data);
    return this.ajaxService.postJson(url, data);
  }

  public externalServiceProviderAssign(recordId: string, attributes: any): Promise<any> {
    const url = '/api/records/' + recordId + '/external-service-provider';
    return this.ajaxService.putJson(url, {data: {attributes}});
  }

  public externalServiceProviderShow(recordId: string, firmCategoryId: number): Promise<any> {
    const url = '/api/records/' + recordId + '/external-service-provider?firmCategoryId=' + firmCategoryId;
    return this.ajaxService.getJson(url);
  }

  public findAllForList(filter: FilterInterface): Promise<any> {
    let url = '/api/records?filter[property]=' + filter.property + '&filter[value]=' + filter.value;

    if (this.firmRepository.getActiveBranch()) {
      url += '&firmId=' + this.firmRepository.getActiveBranch().firmId;
    }
    return this.ajaxService.getJson(url);
  }

  // TODO this is for prototyping the APP records table into desktop
  //  TODO Replace with "real" code
  public findAllForListDesktop(filter: FilterInterface, parameters: UrlParameterInterface): Promise<any> {
    let url = '/api/records/desktop?filter[property]=' + filter.property + '&filter[value]=' + filter.value;

    if (this.firmRepository.getActiveBranch()) {
      url += '&firmId=' + this.firmRepository.getActiveBranch().firmId;
    }
    return this.ajaxService.getJsonWithParameters(url, parameters);
  }
  public findAllForListDesktopCount(filter: FilterInterface, parameters: UrlParameterInterface): Promise<any> {
    let url = '/api/records/desktop/count?filter[property]=' + filter.property + '&filter[value]=' + filter.value;

    if (this.firmRepository.getActiveBranch()) {
      url += '&firmId=' + this.firmRepository.getActiveBranch().firmId;
    }
    return this.ajaxService.getJsonWithParameters(url, parameters);
  }
  // TODO End of protoyping

  public findAllNotesForList(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/notes';
    return this.ajaxService.getJson(url);
  }

  public findReceiptEntries(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/receipt-entries';
    return this.ajaxService.getJson(url);
  }

  public generateDocument(recordId: number, selectedDocuments: any): Promise<any> {
    const url = `/api/records/${recordId}/documents/generate`;
    return this.ajaxService.postJson(url, {data: {attributes: {documents: selectedDocuments}}});
  }

  public getAppraiserVideoCallurl(recordId: string, isMobile: boolean): Promise<any> {
    const url = '/api/records/' + recordId + '/appraiser-video-call-url';
    return this.ajaxService.getJsonWithParameters(url, {isMobile});
  }

  public getAvailableDocuments(recordId: number): Promise<any> {
    const url = '/api/records/' + recordId + '/documents';
    return this.ajaxService.getJson(url);
  }

  public getBasicData(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId;
    return this.ajaxService.getJson(url);
  }

  public getCustomerData(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/customer';
    return this.ajaxService.getJson(url);
  }

  public getFileData(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/files';
    return this.ajaxService.getJson(url);
  }

  public getFirmData(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/firm';
    return this.ajaxService.getJson(url);
  }

  public getInvoiceModalityArticleOptions(recordId: string, serviceRecipientFirmId: string): Promise<SelectOption[]> {
    const url = '/api/records/' + recordId + '/service-recipient/' + serviceRecipientFirmId + '/article-options';
    return this.ajaxService.getJson(url).then((response) => {
      return convertToOptionsFromCollection(response.data);
    });
  }

  public getNoteRecipients(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/note-recipients';
    return this.ajaxService.getJson(url);
  }

  public getRepairSequencePlanData(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/repair-sequence-plan';
    return this.ajaxService.getJson(url);
  }

  public getServiceRecipientOptions(recordId: string): Promise<SelectOption[]> {
    const url = '/api/records/' + recordId + '/service-recipient-options';
    return this.ajaxService.getJson(url).then((response) => {
      return convertToOptionsFromCollection(response.data);
    });
  }

  public getSessionFileCount(uploadSessionId: string, fileCategoryId: number): Promise<any> {
    const url = '/api/records/files/count?uploadSessionId=' + uploadSessionId + '&fileCategoryId=' + fileCategoryId;
    return this.ajaxService.getJson(url);
  }

  public getThumbnailData(recordId: string, thumbnailType: string): Promise<any> {
    const url = '/api/records/' + recordId + '/thumbnails/' + thumbnailType;
    return this.ajaxService.getJson(url);
  }

  public getTransitionData(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/state-transitions';
    return this.ajaxService.getJson(url);
  }

  public hideForGroupDefault(recordId: string): Promise<SelectOption[]> {
    const url = '/api/records/' + recordId + '/hide-for-group-default';
    return this.ajaxService.getJson(url);
  }

  public hideForGroupOptions(recordId: string): Promise<SelectOption[]> {
    const url = '/api/records/' + recordId + '/hide-for-group-options';
    return this.ajaxService.getJson(url).then((response) => {
      return convertToOptionsFromCollection(response.data);
    });
  }

  public isAppraiserVideoCallAvailable(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/appraiser-video-call-availability';
    return this.ajaxService.getJson(url);
  }

  public moveRecordToDamageServiceProvider(recordId: number): Promise<any> {
    const url = '/api/records/' + recordId + '/move-to-damage-service-provider';
    return this.ajaxService.postJson(url, {});
  }

  public moveRecordToFirm(recordId: string, newFirmId: number): Promise<any> {
    const url = '/api/records/' + recordId + '/move-to-firm';
    return this.ajaxService.postJson(url, {data: {attributes: {newFirmId}}});
  }

  public postAppraiserVideoCallHappened(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/appraiser-video-call-happened';
    return this.ajaxService.postJson(url, {});
  }

  public responsibilitiesCarDealerUserAssign(recordId: string, attributes: any): Promise<any> {
    const url = '/api/records/' + recordId + '/responsibilities/car-dealer-user';
    return this.ajaxService.putJson(url, {data: {attributes}});
  }

  public responsibilitiesCarDealerUserOptions(recordId: string): Promise<SelectOption[]> {
    const url = '/api/records/' + recordId + '/responsibilities/car-dealer-user';
    return this.ajaxService.getJson(url).then((response) => {
      return convertToOptionsFromCollection(response.data);
    });
  }

  public responsibilitiesShow(recordId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/responsibilities';
    return this.ajaxService.getJson(url);
  }

  public sendRecordCreationByCustomerMail(creationMailData: any): Promise<any> {
    const url = '/api/records/send-record-creation-by-customer-mail';
    return this.ajaxService.postJson(url, creationMailData);
  }

  public stateSwitch(recordId: string, newStateId: string, data: any): Promise<any> {
    const url = '/api/records/' + recordId + '/state-switch/' + newStateId;
    return this.ajaxService.postJson(url, data);
  }

  public storeNote(recordId: string, noteData: any): Promise<any> {
    const url = '/api/records/' + recordId + '/notes';
    return this.ajaxService.postJson(url, noteData);
  }

  public storeRetroactiveChangesNote(recordId: string, noteData: any): Promise<any> {
    const url = '/api/records/' + recordId + '/retroactive-changes';
    return this.ajaxService.postJson(url, noteData);
  }

  public storeRetroactivePaymentNote(recordId: string, noteData: any): Promise<any> {
    const url = '/api/records/' + recordId + '/retroactive-payment';
    return this.ajaxService.postJson(url, noteData);
  }

  public unsetNotePriority(recordId: string, noteId: string): Promise<any> {
    const url = '/api/records/' + recordId + '/notes/' + noteId + '/unset-priority';
    return this.ajaxService.postJson(url, {});
  }

  public updateCustomerData(recordId: string, customerData: any): Promise<any> {
    const url = '/api/records/' + recordId + '/customer';
    return this.ajaxService.postJson(url, customerData);
  }

  public updateRepairSequencePlanData(recordId: string, form: Form): Promise<any> {
    const url = '/api/records/' + recordId + '/repair-sequence-plan';
    return this.ajaxService.putFormData(url, form);
  }

  private cloneDeep(data: any): any {
    return JSON.parse(JSON.stringify(data));
  }

  private prepareRecordCreation(data: any): any {
    // We must clone the data object, otherwise we remove stuff from the object in the form
    // and switching the type will cause errors.
    data = this.cloneDeep(data);

    const claimType = data.data.attributes.claimType.data.attributes.insuranceType;

    if (claimType === ClaimTypeEnum.CASUALTY) {
      return this.prepareRecordCreationCasualty(data);
    }

    return this.prepareRecordCreationComprehensive(data);
  }

  private prepareRecordCreationCasualty(data: any) {
    delete data.data.attributes.claimType.data.attributes.insuranceComprehensiveType;
    delete data.data.attributes.claimType.data.attributes.hasDifferingPolicyholder;
    data.data.attributes.vehicle.data.attributes.mileage = parseInt(data.data.attributes.vehicle.data.attributes.mileage);

    delete data.data.attributes.comprehensiveInsurancePolicy;
    delete data.data.attributes.differingPolicyholder;

    return data;
  }

  private prepareRecordCreationComprehensive(data: any) {
    delete data.data.attributes.adversary;
    data.data.attributes.vehicle.data.attributes.mileage = parseInt(data.data.attributes.vehicle.data.attributes.mileage);

    const hasDifferingPolicyholder = data.data.attributes.claimType.data.attributes.hasDifferingPolicyholder;
    if (!hasDifferingPolicyholder) {
      delete data.data.attributes.differingPolicyholder;
    }

    return data;
  }
}
