var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    _vm._b(
      {
        attrs: {
          "invalid-feedback": _vm.invalidFeedback,
          label: _vm.label,
          "label-for": _vm.formControlId,
        },
      },
      "b-form-group",
      _vm.$attrs,
      false
    ),
    [
      _c("b-form-input", {
        class: [
          _vm.hideArrows ? "hide-arrows" : "",
          _vm.potentiallyRequired ? "border-yellow" : "",
        ],
        attrs: {
          id: _vm.formControlId,
          disabled: _vm.isReadOnlyProperty,
          max: _vm.max,
          min: _vm.min,
          name: _vm.formControlId,
          required: _vm.isRequiredProperty,
          state: _vm.validationState,
          step: _vm.step,
          type: _vm.type,
          value: _vm.data[_vm.property],
        },
        on: {
          blur: function ($event) {
            return _vm.$emit("blur")
          },
          input: _vm.handleInput,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }