<template>
    <b-form-group v-bind="$attrs" :invalid-feedback="invalidFeedback" :label="label" :label-for="formControlId">
        <b-form-input :id="formControlId"
            :class="[hideArrows ? 'hide-arrows' : '', potentiallyRequired ? 'border-yellow' : '']"
            :disabled="isReadOnlyProperty" :max="max" :min="min" :name="formControlId" :required="isRequiredProperty"
            :state="validationState" :step="step" :type="type" :value="data[property]" @blur="$emit('blur')"
            @input="handleInput"/>
    </b-form-group>
</template>

<script>
    import FormGroupMixin from './FormGroupMixin';

    export default {
        mixins: [FormGroupMixin],
        props: {
            type: {
                type: String,
                default: 'text',
            },
            max: {
                type: Number,
                default: null,
            },
            min: {
                type: Number,
                default: null,
            },
            nullIfEmpty: {
                type: Boolean,
                default: false,
            },
            step: {
                type: String,
                default: '1',
            },
            hideArrows: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['blur'],
        methods: {
            handleInput(input) {
                if (this.nullIfEmpty && !input) {
                    input = null;
                }
                this.data[this.property] = input;
            },
        },
    }
</script>
