import AjaxService from '@services/AjaxService';

export default class AuthRepository {
  private readonly ajaxService: AjaxService;

  public constructor(ajaxService: AjaxService) {
    this.ajaxService = ajaxService;
  }

  public getUserInfo(): Promise<any> {
    const url = '/api/auth';
    return this.ajaxService.getJson(url);
  }

  public login(username: string, password: string): Promise<any> {
    const url = '/api/auth';
    return this.ajaxService.postJson(url, {data: {attributes: {username, password}}});
  }

  public loginByExternalPin(pin: string, damageRecordId: number): Promise<any> {
    const url = '/api/login-by-external-pin';
    return this.ajaxService.postJson(url, {data: {attributes: {pin, damageRecordId}}});
  }

  public logout(): Promise<any> {
    const url = '/api/auth/logout';
    return this.ajaxService.postJson(url, {});
  }
}
