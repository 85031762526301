import Iframe from '../components/helpers/Iframe';
import MessagesSidebarWrapper from '@desktop/components/messages/SidebarWrapper';
import RecordStateOverview from '@components/record/state/StateOverview';
import {
    BAlert,
    BBadge,
    BButton,
    BCard,
    BCardText,
    BCollapse,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormSelect,
    BFormTextarea,
    BLink,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BTable,
    BTableSimple,
    BPopover,
    BSpinner
} from 'bootstrap-vue/src';
import FormGroupBtnSubmit from '@components/form/FormGroupBtnSubmit';
import FormGroupInput from '@components/form/FormGroupInput';
import FormGroupOptionalBoolean from '@components/form/FormGroupOptionalBoolean';
import StripedBlockWithHeader from '@components/StripedBlockWithHeader';
import FormGroupTextarea from '@components/form/FormGroupTextarea';
import FormGroupSelectSearchable from '@components/form/FormGroupSelectSearchable';
import FormGroupSelect from '@components/form/FormGroupSelect';
import FlashMessenger from '@desktop/components/helpers/FlashMessenger';
import RecordTableRow from '@desktop/components/record/list/RecordTableRow';
import RecordTableFirmNameWithTooltip from '@desktop/components/record/list/RecordTableFirmNameWithTooltip';
import FormGroupCheckbox from '@components/form/FormGroupCheckbox';
import FormGroupCheckboxGroup from '@components/form/FormGroupCheckboxGroup';
import LoadingIndicator from '@components/LoadingIndicator';
import FoldableBase from '@components/foldable/Base.vue';
import HideForGroup from '@components/record/detail/HideForGroup';

export default {
    'b-alert': BAlert,
    'b-badge': BBadge,
    'b-button': BButton,
    'b-card': BCard,
    'b-card-text': BCardText,
    'b-collapse': BCollapse,
    'b-form': BForm,
    'b-form-checkbox': BFormCheckbox,
    'b-form-checkbox-group': BFormCheckboxGroup,
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
    'b-input': BFormInput,
    'b-input-group': BInputGroup,
    'b-input-group-append': BInputGroupAppend,
    'b-form-radio-group': BFormRadioGroup,
    'b-form-select': BFormSelect,
    'b-form-textarea': BFormTextarea,
    'b-img': BImg,
    'b-link': BLink,
    'b-pagination': BPagination,
    'b-table': BTable,
    'b-table-simple': BTableSimple,
    'b-popover': BPopover,
    'b-spinner': BSpinner,

    // Customized Bootstrap Vue components
    'b-modal': () => import(/* webpackChunkName: "desktop/components/popup/modal" */ '@desktop/components/popup/Modal'),

    // Global components
    'csp-popover': () => import(/* webpackChunkName: "components/popover" */ '@components/Popover'),
    'csp-flash-messenger': FlashMessenger,
    'csp-form-group-checkbox': FormGroupCheckbox,
    'csp-form-group-checkbox-group': FormGroupCheckboxGroup,
    'csp-form-datepicker-fallback': () => import(/* webpackChunkName: "components/form/datepicker-fallback" */ '@components/form/DatePickerFallback'),
    'csp-form-group-btn-submit': FormGroupBtnSubmit,
    'csp-form-group-datepicker': () => import(/* webpackChunkName: "components/form/form-group-datepicker" */ '@components/form/FormGroupDatePicker'),
    'csp-form-group-input': FormGroupInput,
    'csp-form-group-input-currency' : () => import(/* webpackChunkName: "components/form/form-group-input-currency" */ '@components/form/FormGroupInputCurrency'),
    'csp-form-group-optional-bool': FormGroupOptionalBoolean,
    'csp-form-group-select': FormGroupSelect,
    'csp-form-group-select-searchable': FormGroupSelectSearchable,
    'csp-form-group-textarea': FormGroupTextarea,
    'csp-base-file-upload': () => import(/* webpackChunkName: "components/file/file-upload-base" */ '@components/file/BaseFileUpload'),
    'csp-striped-block': StripedBlockWithHeader,
    'csp-analytics-bar-chart': () => import(/* webpackChunkName: "components/analytics/analytics-bar-chart" */ '@components/analytics/AnalyticsBarChart'),
    'csp-loading-indicator': LoadingIndicator,
    'csp-file-gallery': () => import('@components/file/Gallery.vue'),
    'csp-hide-for-group': HideForGroup,

    // Desktop components
    'csp-firm-files': () => import(/* webpackChunkName: "desktop/components/firm/firmfiles" */ '@desktop/components/firm/FirmFiles'),
    'csp-firm-documents': () => import(/* webpackChunkName: "desktop/components/firm/firm-documents" */ '@desktop/components/firm/FirmDocuments'),
    'csp-firm-document-upload': () => import(/* webpackChunkName: "desktop/components/firm/firm-document-upload" */ '@desktop/components/firm/FirmDocumentUpload'),
    'csp-iframe': Iframe,
    'csp-messages-sidebar': () => import(/* webpackChunkName: "desktop/components/messages/sidebar" */ '@desktop/components/messages/Sidebar'),
    'csp-messages-sidebar-wrapper': MessagesSidebarWrapper,
    'csp-popup-display': () => import(/* webpackChunkName: "desktop/components/popup/popup-display" */ '@desktop/components/popup/PopupDisplay'),
    'csp-record-state-overview': RecordStateOverview,
    'csp-formelement-multiselectsearchable': () => import(/* webpackChunkName: "desktop/components/formelements/multiselect-searchable" */ '@desktop/components/formelements/MultiSelectSearchable'),
    'csp-formelement-treeselectmultiple': () => import(/* webpackChunkName: "desktop/components/formelements/treeselect-multiple" */ '@desktop/components/formelements/TreeSelectMultiple'),
    'csp-record-file-list': () => import('@desktop/components/record/RecordFileList'),

    // App components
    'csp-foldable-base': FoldableBase,
    'csp-record-accident-contract-field': () => import(/* webpackChunkName: "desktop/components/record/detail/accident/contract-field" */ '@desktop/components/record/detail/accident/ContractField'),
    'csp-record-damage-image-select': () => import(/* webpackChunkName: "components/record/record-damage-image-select" */ '@components/record/RecordDamageImageSelect'),
    'csp-record-file-drop': () => import(/* webpackChunkName: "desktop/components/record/record-file-drop" */ '@desktop/components/record/RecordFileDrop'),
    'csp-record-table-file-drop': () => import(/* webpackChunkName: "desktop/components/record/list/record-table-file-drop" */ '@desktop/components/record/list/RecordTableFileDrop'),
    'csp-record-table-firm-name-with-tooltip': RecordTableFirmNameWithTooltip,
    'csp-record-table-row': RecordTableRow,
    'csp-record-header': () => import(/* webpackChunkName: "desktop/components/record/detail/record-header" */ '@desktop/components/record/detail/RecordHeader'),
    'csp-record-cost-unit-table': () => import(/* webpackChunkName: "desktop/components/record/detail/record-cost-unit-table" */ '@desktop/components/record/detail/costunit/CostUnitTable'),

    // Views
    'csp-firm-association-list': () => import(/* webpackChunkName: "desktop/views/firmassociation/AssociationList" */ '@desktop/views/firmassociation/AssociationList'),
    'csp-firm-appraiser-assignment': () => import(/* webpackChunkName: "desktop/views/firm/treeselect" */ '@desktop/views/firm/Appraiser'),
    'csp-firm-invoicing-modalities': () => import('@desktop/views/invoicingModality/FirmInvoicingModalities.vue'),
    'csp-firmgroup-firmlevels': () => import(/* webpackChunkName: "desktop/views/firmgroup/firmlevels" */ '@desktop/views/firmgroup/Firmlevels'),
    'csp-firmgroup-invoicing-modalities': () => import('@desktop/views/invoicingModality/FirmGroupInvoicingModalities.vue'),
    'csp-receipt-generate': () => import('@desktop/views/receipt/ReceiptGenerate.vue'),
    'csp-receipt-list': () => import('@desktop/views/receipt/ReceiptList.vue'),
    'csp-record-detail-accident': () => import(/* webpackChunkName: "desktop/views/record/detail/accident" */ '@desktop/views/record/detail/Accident'),
    'csp-record-detail-documents-modal': () => import(/* webpackChunkName: "desktop/views/record/detail/documents-modal" */ '@desktop/views/record/detail/DocumentsModal'),
    'csp-record-file-signature-modal': () => import('@desktop/views/record/detail/FileSignatureModal'),
    'csp-record-move-to-damage-service-provider': () => import('@desktop/views/record/MoveToDamageServiceProvider'),
    'csp-record-move-to-firm': () => import(/* webpackChunkName: "desktop/views/record/move-to-firm" */ '@desktop/views/record/MoveToFirm'),
    'csp-analytic': () => import(/* webpackChunkName: "desktop/views/analytic" */ '@desktop/views/Analytic'),
    'csp-link-for-record-creation-by-customer': () => import(/* webpackChunkName: "desktop/views/link-for-record-creation-by-customer" */ '@desktop/views/LinkForRecordCreationByCustomer'),
    'csp-record-list': () => import('@desktop/views/record/List.vue'),
    'csp-record-receipt-entries': () => import('@desktop/views/record/ReceiptEntries.vue'),
};
